import * as React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from 'react-bootstrap';

const BlockIntroduction = ({title, intro, customIntro=false, padding={bottom:80, top:80,left:15,right:15}, colWidth={lg:6, md:10} }) => {
  if(customIntro){
    return(
      <Container className="component" style={{padding:`${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`}}>
        <Row>
        <Col lg={colWidth.lg} md={colWidth.md}>
          <p className="bolder opacity-40">
            {title}    
          </p>
          {intro}    
        </Col>
        </Row>
      </Container>
    )
  }
  return(
    <Container className="component"  style={{padding:`${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`}}>
      <Row>
      <Col lg={colWidth.lg} md={colWidth.md}>
        <p className="bolder opacity-40">
          {title}    
        </p>
        {intro}    
      </Col>
      </Row>
    </Container>
  )
}
BlockIntroduction.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  padding: PropTypes.object,
  colWidth: PropTypes.object,
  customIntro: PropTypes.bool,
}

export default BlockIntroduction