import * as React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlockIntroduction from "../components/blockIntroduction"
import ContactForm from "../components/contactForm"
import HeaderTextArrowDown from "../components/headerTextArrowDown"
import Cta from "../components/cta"
import MetaImage from "../images/featured/loyall-featured-image-discovery-light.jpg"

const SecondPage = (props) => (
  <Layout>
    <div className="page">
      <Seo
        title="Loyall – Ontdek de mogelijkheden voor jouw bedrijf" 
        image={{src: MetaImage, height: 628, width: 1200}}
        description="Ons team van digitale lifecycle experts staan voor je klaar: wij weten wat nodig is om jouw doelgroep in loyale klanten te veranderen. Neem contactop voor een vrijblijvend gesprek."
        pathname={props.location.pathname}    
      />
      <BlockIntroduction
        title="Discovery"
        intro={
          <h2>
            Let’s <span className="text-gradient">disco</span>ver the
            possibilities together.
          </h2>
        }
        customIntro={true}
        colWidth={{ lg:8, md:10 }}
      />
      <HeaderTextArrowDown
        customText={<p className="subtitle">Vul onderstaand formulier in en we nemen contact met je op. Geen zin in formulieren? Bel ons op <a className="d-inline-block" href="tel:0031202101234">+31 20 210 1234</a> of mail naar <a className="d-inline-block" href="mailto:info@loyall.co">info@loyall.co</a></p>}
        elementId="intro-discovery"
        padding={{ top: 0, bottom: 80, left: 15, right: 15 }}
      />
      <ContactForm 
        title="Hey, leuk dat je er bent!"
        formId="24af6590-f084-46b5-b9cf-10fe6692740e"
      />
      <Cta
        verticalStyling={false}
        title=""
        intro="Zo werden we één: het verhaal achter Loyall."
        link="/the-rebranding-story"
        linkText="The rebranding story"
      />
    </div>
  </Layout>
)

export default SecondPage
