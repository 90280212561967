import * as React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from 'react-bootstrap';
import HubspotForm from 'react-hubspot-form'

const ContactForm = ({title, formId}) => {
  return(
    <Container className="component pb-0">
      <Row>
      <Col lg={4}>
        <h6 className="pb-4">
          {title}    
        </h6>
      </Col>
      <Col lg={8} className="hs-form">
        <HubspotForm
          portalId='5195515'
          formId={formId}
          onSubmit={() => console.log('Submit!')}
          onReady={(form) => console.log('Form ready!')}
          loading={<div>Loading...</div>}
        />
        </Col>
      </Row>
    </Container>
  )
}
ContactForm.propTypes = {
  title: PropTypes.string,
  formId: PropTypes.string,
}

export default ContactForm
