import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons"

const ScrollArrowDown = (props) => {

    function scrollHandler(){
        if (typeof window !== 'undefined') {
            let target = document.getElementById(props.target);
            window.scrollTo(0,target.offsetTop+target.getBoundingClientRect().height-150);
        }
    }

    return (
        <div className="scroll-down-arrow-wrapper">
            <button className="scroll-down-arrow" onClick={()=>scrollHandler()}>
            <FontAwesomeIcon
                icon={faArrowDown}
              />
            </button>
        </div>
    )
}
export default ScrollArrowDown
