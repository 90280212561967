import * as React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHorizontalRule } from '@fortawesome/pro-regular-svg-icons'

const Cta = ({title, intro, link, linkText, verticalStyling=false, className, titleSize="h4" }) => (
  <Container className={`${className} component cta`}>
    <Row className="align-items-end">
    <Col lg={8} className={`${verticalStyling ? null : "cta_column"}`}>
      <p className="bolder opacity-40">
        {title}    
      </p>
      {verticalStyling ? 
      <h3 className={`mb-lg-4`}>
        {intro}    
      </h3>
      :  
      <h4 className={`${titleSize} mb-lg-0`}>
        {intro}    
      </h4>
      }
    </Col>
    <Col lg={`${verticalStyling ? 12 : 4}`} className={`${verticalStyling ? null : "cta_column text-lg-end"}`}>
    {
      link !== "" ? 
        <Link to={link} className={`${verticalStyling ? "btn btn-solid btn-loyall btn-dark disabled" : "btn btn-solid btn-loyall"}`}>
          {linkText}
          <FontAwesomeIcon icon={faHorizontalRule} />
        </Link>
      : null
    }
    </Col>
    </Row>
  </Container>
)

Cta.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  verticalStyling: PropTypes.bool,
  className: PropTypes.string,
  titleSize: PropTypes.string,
}

export default Cta