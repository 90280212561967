import * as React from "react"
import PropTypes from "prop-types"
import {Row, Container, Col} from "react-bootstrap"
import ScrollArrowDown from "./elements/scrollArrowDown"
const HeaderTextArrowDown = ({text=null,customText=null,padding={bottom:80, top:80,left:15,right:15}, elementId}) =>{ 
    return (
        <Container id={`${elementId}`} className="header-text-arrow-down-wrapper" style={{padding:`${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`}}>
            <Row>
                <Col xs={{ span: 12, order:2 }}  md={4} className="d-flex flex-column justify-content-end">
                    <ScrollArrowDown target={elementId}/>
                </Col>
                <Col xs={{ span: 12, order:1 }} md={{ span: 7, offset: 1, order:2 }}>
                    {customText !== null ? customText : null}
                    {text !== null ? <p className="subtitle">{text}</p> : null}
                </Col>
            </Row>
        </Container>
    )
}
HeaderTextArrowDown.propTypes = {
    text: PropTypes.string,
    elementId: PropTypes.string,
    padding: PropTypes.object
}

export default HeaderTextArrowDown